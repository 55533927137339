@font-face {
  font-family: 'Spoqa Han Sans';
  font-style: normal;
  font-weight: 700;
  src: local('Spoqa Han Sans Bold'), url('./assets/fonts/SpoqaHanSansBold.woff2') format('woff2'),
    url('./assets/fonts/SpoqaHanSansBold.woff') format('woff'),
    url('./assets/fonts/SpoqaHanSansBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Spoqa Han Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Spoqa Han Sans Regular'), url('./assets/fonts/SpoqaHanSansRegular.woff2') format('woff2'),
    url('./assets/fonts/SpoqaHanSansRegular.woff') format('woff'),
    url('./assets/fonts/SpoqaHanSansRegular.ttf') format('truetype');
}

@font-face {
  font-family: 'Spoqa Han Sans';
  font-weight: 300;
  src: local('Spoqa Han Sans Light'), url('./assets/fonts/SpoqaHanSansLight.woff2') format('woff2'),
    url('./assets/fonts/SpoqaHanSansLight.woff') format('woff'),
    url('./assets/fonts/SpoqaHanSansLight.ttf') format('truetype');
}

@font-face {
  font-family: 'Spoqa Han Sans';
  font-weight: 100;
  src: local('Spoqa Han Sans Thin'), url('./assets/fonts/SpoqaHanSansThin.woff2') format('woff2'),
    url('./assets/fonts/SpoqaHanSansThin.woff') format('woff'),
    url('./assets/fonts/SpoqaHanSansThin.ttf') format('truetype');
}

:root {
  --h1: 36px;
  --h2: 26px;
  --h3: 20px;
  --p1: 16px;
  --c1: 14px;
  --c2: 12px;

  --h1LineHeight: 50.4px;
  --h2LineHeight: 36.4px;
  --h3LineHeight: 28px;
  --p1LineHeight: 28px;
  --c1LineHeight: 24px;
  --c2LineHeight: 21px;

  --gray0: #ffffff;
  --gray50: #fafafa;
  --gray100: #f5f5f5;
  --gray200: #e8e8e8;
  --gray300: #dbdbdb;
  --gray400: #cccccc;
  --gray500: #a8a8a8;
  --gray600: #757575;
  --gray700: #333333;
  --gray800: #212121;
  --gray900: #000000;

  --red100: #f7d7d7;
  --red200: #eeadad;
  --red300: #e58383;
  --red400: #d32f2f;
  --red500: #ab2424;
  --red600: #811b1b;
  --red700: #571212;
}

.wkdk {
  --brand100: #fff0cb;
  --brand200: #ffecb3;
  --brand300: #ffec3d;
  --brand400: #fede00;
  --brand500: #ffa000;
  --brand600: #fb8c00;
  --brand700: #e65100;

  --design100: #ccddff;
  --design200: #99bbff;
  --design300: #3d5afe;
  --design400: #003399;
  --design500: #002266;
  --design600: #001657;
  --design700: #001133;

  --onBrand100: var(--gray800);
  --onBrand200: var(--gray800);
  --onBrand300: var(--gray800);
  --onBrand400: var(--gray800);
  --onBrand500: var(--gray0);
  --onBrand600: var(--gray0);
  --onBrand700: var(--gray0);
}

.wkdk .FAB_fill {
  background-color: var(--brand400);
}

.wkdk .FAB_fill > svg > path {
  stroke: var(--onBrand400);
}

.wkdk .FAB_line > svg > path {
  stroke: var(--brand600);
}

.wkdk .check-option-list-row_icon > svg:last-of-type > path {
  stroke: var(--brand500);
}

.wkdk .snackbar_text-button {
  background-color: transparent !important;
}

.wkdk .snackbar_text-button > p {
  color: var(--brand400);
}

.wkdk .toggle-button_active {
  background-color: var(--brand500);
}

.wkdk .top_title_brand {
  color: var(--brand500) !important;
}

.brwnie {
  --brand100: #ccddff;
  --brand200: #99bbff;
  --brand300: #3d5afe;
  --brand400: #003399;
  --brand500: #002266;
  --brand600: #001657;
  --brand700: #001133;

  --design100: #fff0cb;
  --design200: #ffecb3;
  --design300: #ffec3d;
  --design400: #fede00;
  --design500: #ffa000;
  --design600: #fb8c00;
  --design700: #e65100;

  --onBrand100: var(--gray800);
  --onBrand200: var(--gray800);
  --onBrand300: var(--gray0);
  --onBrand400: var(--gray0);
  --onBrand500: var(--gray0);
  --onBrand600: var(--gray0);
  --onBrand700: var(--gray0);
}

.brwnie .FAB_fill {
  background-color: var(--brand300);
}

.brwnie .FAB_fill > svg > path {
  stroke: var(--onBrand300);
}

.brwnie .FAB_line > svg > path {
  stroke: var(--brand300);
}

.brwnie .check-option-list-row_icon > svg:last-of-type > path {
  stroke: var(--brand300);
}

.brwnie .snackbar_text-button {
  background-color: transparent !important;
}

.brwnie .snackbar_text-button > p {
  color: var(--brand100);
}

.brwnie .toggle-button_active {
  background-color: var(--brand300);
}

.brwnie .top_title_brand {
  color: var(--brand300) !important;
}

.gs25 {
  --brand100: #cce5ff;
  --brand200: #66b0ff;
  --brand300: #3396ff;
  --brand400: #007cff;
  --brand500: #0063cc;
  --brand600: #003399;
  --brand700: #001933;

  --design100: #d0fbff;
  --design200: #9df6ff;
  --design300: #37ecff;
  --design400: #00d4ea;
  --design500: #00a6b7;
  --design600: #007884;
  --design700: #004951;

  --onBrand100: var(--gray800);
  --onBrand200: var(--gray0);
  --onBrand300: var(--gray0);
  --onBrand400: var(--gray0);
  --onBrand500: var(--gray0);
  --onBrand600: var(--gray0);
  --onBrand700: var(--gray0);
}

.gs25 .FAB_fill {
  background-color: var(--brand400);
}

.gs25 .FAB_fill > svg > path {
  stroke: var(--onBrand400);
}

.gs25 .FAB_line > svg > path {
  stroke: var(--brand400);
}

.gs25 .check-option-list-row_icon > svg:last-of-type > path {
  stroke: var(--brand400);
}

.gs25 .snackbar_text-button {
  background-color: transparent !important;
}

.gs25 .snackbar_text-button > p {
  color: var(--brand400);
}

.gs25 .toggle-button_active {
  background-color: var(--brand400);
}

.gs25 .top_title_brand {
  color: var(--brand400) !important;
}

.lalavla {
  --brand100: #fcdee9;
  --brand200: #f9aecb;
  --brand300: #f24f8e;
  --brand400: #ee206f;
  --brand500: #cb1058;
  --brand600: #9c0c43;
  --brand700: #6d082f;

  --design100: #e2e8f0;
  --design200: #bfc8d4;
  --design300: #a0aec0;
  --design400: #718096;
  --design500: #4a5568;
  --design600: #00283f;
  --design700: #1a202c;

  --onBrand100: var(--gray800);
  --onBrand200: var(--gray0);
  --onBrand300: var(--gray0);
  --onBrand400: var(--gray0);
  --onBrand500: var(--gray0);
  --onBrand600: var(--gray0);
  --onBrand700: var(--gray0);
}

.lalavla .FAB_fill {
  background-color: var(--brand400);
}

.lalavla .FAB_fill > svg > path {
  stroke: var(--onBrand400);
}

.lalavla .FAB_line > svg > path {
  stroke: var(--brand400);
}

.lalavla .check-option-list-row_icon > svg:last-of-type > path {
  stroke: var(--brand400);
}

.lalavla .snackbar_text-button {
  background-color: transparent !important;
}

.lalavla .snackbar_text-button > p {
  color: var(--brand400);
}

.lalavla .toggle-button_active {
  background-color: var(--brand400);
}

.lalavla .top_title_brand {
  color: var(--brand400) !important;
}
