.Dropdown-root {
  position: relative;
}

.Dropdown-placeholder {
  font-size: 1.4rem;
  line-height: 2rem;
  color: #003399;
  white-space: nowrap;
}

.Dropdown-control {
  position: relative;
  overflow: hidden;
  background-color: white;
  border: 1px solid #003399;
  border-radius: 0.8rem;
  box-sizing: border-box;
  color: #333;
  cursor: pointer;
  outline: none;
  padding: 8px 52px 8px 10px;
  transition: all 200ms ease;
}

/* .Dropdown-control:hover {
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
} */

.Dropdown-arrow {
  border-color: #003399 transparent transparent;
  border-style: solid;
  border-width: 0.5rem 0.5rem 0;
  content: ' ';
  display: block;
  height: 0;
  margin-top: -ceil(2.5);
  position: absolute;
  right: 1rem;
  top: 1.4rem;
  width: 0;
}

.is-open .Dropdown-arrow {
  border-color: transparent transparent #003399;
  border-width: 0 0.5rem 0.5rem;
}

.Dropdown-menu {
  background-color: white;
  border: 1px solid #003399;
  border-radius: 0.8rem;
  /* box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06); */
  box-sizing: border-box;
  margin-top: -1px;
  max-height: 24rem;
  overflow-y: auto;
  position: absolute;
  top: 100%;
  width: 100%;
  height: fit-content;
  z-index: 500;
  text-align: center;
  -webkit-overflow-scrolling: touch;
}

.Dropdown-menu::-webkit-scrollbar {
  width: 0.4rem;
}

.Dropdown-menu::-webkit-scrollbar-thumb {
  border: 0.1rem solid white;
  width: 0.4rem;
  border-radius: 0.8rem;
  background-color: #b1b1b5;
}

.Dropdown-menu::-webkit-scrollbar-track {
  margin: 0.36rem 0;
  border-radius: 0.8rem;
  background-color: transparent;
}

.Dropdown-menu .Dropdown-group > .Dropdown-title {
  padding: 0.8rem 1rem;
  color: rgba(51, 51, 51, 1);
  font-weight: bold;
  text-transform: capitalize;
}

.Dropdown-option {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  padding: 0.8rem 1rem;
  font-size: 1.4rem;
  line-height: 2rem;
  color: #003399;
}

.Dropdown-option:last-child {
  border-bottom-right-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.Dropdown-option:hover {
  background-color: #003399;
  color: white;
}

.Dropdown-option.is-selected {
  background-color: #003399;
  color: white;
}

.Dropdown-noresults {
  box-sizing: border-box;
  color: #ccc;
  cursor: default;
  display: block;
  padding: 0.8rem 1rem;
}
